<template>
  <v-card class="elevation-0 ma-2" :loading="store.state.loading">
    <v-form ref="form">
      <v-container>
        <form-register-main-info />

        <v-divider></v-divider>
        <form-register-actions
          @saveForm="saveForm(true)"
          @addItemOnStart="addItemOnStart"
        />

        <item-register
          @saveForm="saveForm(false)"
          v-for="(item, index) of lazyLoadedItems"
          v-model="store.state.items[index]"
          :index="index"
          :key="item"
        />

        <v-divider></v-divider>

        <v-row v-show="store.state.loading == true">
          <v-col class="d-flex flex-column mx-auto my-4">
            <span class="flex-one mx-auto my-3">
              Carregando Novas Questões
            </span>
            <v-progress-circular
              class="mx-auto flex-one form-register-loader"
              indeterminate
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>
<script>
import FormRegisterActions from "../components/form_register/FormRegisterActions.vue";
import FormRegisterMainInfo from "../components/form_register/FormRegisterMainInfo.vue";
import ItemRegister from "../components/item_management/ItemRegister.vue";
import FormStore from "../store/FormStore";
import { v1 as uuid } from "uuid";

export default {
  name: "DocumentsRegister",
  components: {
    ItemRegister,
    FormRegisterMainInfo,
    FormRegisterActions,
  },
  props: {
    projectId: { type: Number, default: null },
  },
  data() {
    return {
      store: new FormStore(),
      itemsCount: 0,
      id: null,
      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: null,
        y: "bottom",
        multiLine: false,
      },
    };
  },

  async mounted() {
    this.id = this.projectId
      ? this.$route.params.childrenId
      : this.$route.params.id;

    if (this.id) {
      await this.getData();
    } else {
      this.store.clearData();
    }

    this.implementInfinitScrolling();
  },

  computed: {
    lazyLoadedItems() {
      const serializedPage = this.store.state.page || 1;

      const result = this.store.state.items.slice(
        0,
        serializedPage * this.store.state.perPage
      );

      return result;
    },
  },

  methods: {
    implementInfinitScrolling() {
      window.onscroll = () => {
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight ===
          document.documentElement.offsetHeight;

        if (bottomOfWindow) {
          this.loadMoreData();
        }
      };
    },

    loadMoreData() {
      if (this.store.state.items.length < this.store.state.perPage) {
        return;
      }

      this.store.state.loading = true;

      setTimeout(() => {
        this.store.state.page += 1;

        this.store.state.loading = false;
      }, 1800);
    },

    async getData() {
      try {
        this.store.setLoading(true);

        const response = await this.minamelServices
          .DocumentService()
          .show(this.id);

        this.store.state.name = response.data.lastForm.name;
        this.store.state.filled_by = response.data.lastForm.filled_by;
        this.store.state.items = response.data.lastForm.formFields;
        this.store.state.form_id = response.data.lastForm.id;

        this.store.state.items.forEach((item) => {
          item.config = JSON.parse(item.config);
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.store.setLoading(false);
      }
    },

    addItemOnStart() {
      const item = {
        id: uuid(),
        type: "TEXTAREA",
        is_required: 1,
        config: {
          options: [],
        },
      };

      this.store.addItem(item, 0);
    },

    async saveForm(closePage = false) {
      try {
        this.store.setLoading(true);

        if (!this.$refs || !this.$refs.form) {
          return;
        }

        let formValidate = this.$refs.form.validate();

        if (!formValidate) {
          this.snackbar.color = "warning";
          this.snackbar.message = "Verifique os campos em destaque.";
          this.$root.$SnackBar.show(this.snackbar);
          return;
        }

        if (!this.store.state.items.length) {
          this.snackbar.color = "warning";
          this.snackbar.message =
            "O formulário deve conter pelo menos um item.";
          this.$root.$SnackBar.show(this.snackbar);
          return;
        }

        if (this.id) {
          await this.update();
        } else {
          await this.create();
        }

        if (closePage) {
          this.$router.go(-1);
        }

        this.snackbar.color = "success";
        this.snackbar.message = "Formulário salvo com sucesso.";
        this.$root.$SnackBar.show(this.snackbar);
      } catch (e) {
        console.error("FormsRegister::save -", e);
      } finally {
        this.store.setLoading(false);
      }
    },

    async create() {
      try {
        this.store.sortAllItems();

        this.store.state.type = "RECORD";
        const response = await this.minamelServices
          .DocumentService()
          .create(this.store.state);

        if (response) {
          this.snackbar.color = "success";
          this.snackbar.message = "Documento incluído com sucesso!";
          this.$root.$SnackBar.show(this.snackbar);
        }

        this.id = response.id;
        this.store.state.items = { ...this.store.state.items, ...response };
        this.store.state.items.form_id = response.id;

        return;
      } catch (err) {
        console.error("FormsRegister::create - ", err);
        throw err;
      }
    },

    async update() {
      try {
        this.store.sortAllItems();

        const response = await this.minamelServices
          .DocumentService()
          .update(this.store.state.form_id, this.store.state);

        if (response) {
          this.snackbar.color = "success";
          this.snackbar.message = "Documento atualizado com sucesso!";
          this.$root.$SnackBar.show(this.snackbar);
        }

        this.id = response.id;

        return;
      } catch (err) {
        console.error("FormsRegister::update - ", err);
        throw err;
      }
    },
  },
};
</script>

<style>
.form-register-loader {
  min-height: 56px;
}
</style>
