<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-row style="padding-bottom: 18px !important">
    <v-col xs="10" sm="10" md="11">
      <v-card
        @click="onCardClick"
        elevation="1"
        :class="activeCard ? 'border-color' : ''"
        class="border pa-4"
      >
        <v-row row wrap grid-list-md>
          <v-col md="8" v-if="model.type !== 'TEXTBLOCK'">
            <v-text-field
              v-model="model.label"
              label="Pergunta"
              required
            ></v-text-field>
          </v-col>

          <v-col md="4">
            <v-select
              single-line
              v-model="model.type"
              :items="typeFieldItems"
              :prepend-inner-icon="getIcon(model.type)"
            ></v-select>
          </v-col>

          <v-col md="8" v-show="canShowHelpText">
            <v-text-field
              v-model="model.help_text"
              label="Texto de ajuda"
              required
            ></v-text-field>
          </v-col>

          <v-col
            class="grey lighten-5"
            pa-4
            md="12"
            v-if="model.type === 'RADIO' || model.type === 'CHECKBOX'"
          >
            <v-label>Opções de escolha:</v-label>

            <FieldItemRegister
              v-for="field of model.config.options"
              :item="field"
              :key="field.id"
              :fieldType="model.type"
              :field="model"
            />

            <v-row>
              <v-col md="4">
                <v-text-field
                  readonly
                  :prepend-icon="
                    model.type === 'CHECKBOX'
                      ? 'check_box'
                      : 'radio_button_checked'
                  "
                  placeholder="Adicionar opção"
                  @click="addFieldItem"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row row wrap v-if="model.type === 'TEXTBLOCK'">
          <v-col md="12" class="d-flex">
            <editor
              class="tinymce-editor"
              v-if="!loadingEditor"
              v-model="model.help_text"
              :api-key="CONFIG.VUE_APP_API_KEY_TINYMCE"
              :init="init"
              @onChange="onEditor"
            />

            <v-progress-circular
              v-else
              indeterminate
              class="flex-one mx-auto"
            />
          </v-col>
        </v-row>

        <final-signature-item
          v-if="model.type === 'FINAL_SIGNATURE'"
          v-model="model.help_text"
        />

        <v-divider v-if="model.type !== 'TEXTBLOCK'"></v-divider>

        <v-row row wrap>
          <v-spacer></v-spacer>

          <v-col xs="12" md="2">
            <v-switch
              v-model="model.is_required"
              color="#b9041c"
              label="Obrigatório"
              class="mt-2"
            ></v-switch>
          </v-col>

          <v-col md="1" xs="12">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary--text" v-on="on" @click="removeItem" icon>
                  <v-icon>delete</v-icon>
                </v-btn>
              </template>

              <span>Remover questão</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <item-register-actions
      :item="model"
      :activeCard="activeCard"
      :index="index"
      @saveForm="saveForm"
      @addNewItemAboveThis="addNewItemAboveThis"
      @toggleItemVisibilityOnMobile="toggleItemVisibilityOnMobile"
    />

    <item-variable-dialog
      v-model="selectVariableDialog"
      @onSelectVariable="onSelectVariable"
    />
  </v-row>
</template>

<script>
import FieldItemRegister from "./FieldItemRegister";

import Editor from "@tinymce/tinymce-vue";
import FormStore from "../../store/FormStore";
import ItemRegisterActions from "./ItemRegisterActions.vue";
import ModelManagementMixin from "@/helpers/ModelManagementMixin";
import ItemVariableDialog from "./ItemVariableDialog.vue";
import FinalSignatureItem from "./item_types/FinalSignatureItem.vue";
import { v1 as uuid } from "uuid";

export default {
  name: "ItemRegister",
  components: {
    FieldItemRegister,
    Editor,
    ItemRegisterActions,
    ItemVariableDialog,
    FinalSignatureItem,
  },
  props: {
    index: Number,
  },
  mixins: [ModelManagementMixin],
  data() {
    return {
      store: new FormStore(),
      // Tinymc config
      init: {
        language: "pt_BR",
        selselector: "textarea",
        plugins:
          "a11ychecker advcode casechange export formatpainter linkchecker autolink lists checklist media mediaembed pageembed permanentpen powerpaste table advtable tinycomments tinymcespellchecker",
        toolbar:
          "a11ycheck addcomment showcomments casechange checklist code export formatpainter pageembed permanentpen table",
        toolbar_mode: "floating",
        tinycomments_mode: "embedded",
        tinycomments_author: "Author name",
      },
      position: 0,
      lastValue: "",
      activeCard: true,
      loadingEditor: false,
      fieldItems: [],
      fieldItemsCount: 0,
      typeFieldItems: [
        {
          text: "Objetiva",
          value: "RADIO",
        },
        {
          text: "Multipla escolha",
          value: "CHECKBOX",
        },
        {
          text: "Texto",
          value: "TEXTAREA",
        },
        {
          text: "Data",
          value: "DATE",
        },
        {
          text: "Assinatura",
          value: "SIGNATURE",
        },
        {
          text: "Bloco de texto",
          value: "TEXTBLOCK",
          doesntHaveHelpText: true,
        },
        {
          text: "Desenho",
          value: "DRAW",
        },
        {
          text: "Assinatura Final",
          value: "FINAL_SIGNATURE",
          doesntHaveHelpText: true,
        },
      ],
      selectVariableDialog: false,
      selectedVariable: "",
    };
  },

  created() {
    this.$forceUpdate();

    // this.removeFieldItem(data);
    this.loadingEditor = true;

    setTimeout(() => {
      this.loadingEditor = false;
    }, 400);
  },

  computed: {
    canShowHelpText() {
      const selectedType = this.typeFieldItems.filter(
        (type) => this.model.type == type.value
      );

      return !selectedType[0].doesntHaveHelpText;
    },
  },

  methods: {
    toggleItemVision() {
      // eslint-disable-next-line vue/no-mutating-props
      this.model.not_show_on_mobile = !this.model.not_show_on_mobile;

      this.$forceUpdate();
    },

    saveForm() {
      this.$emit("saveForm");
    },

    addNewItemAboveThis(index) {
      const item = {
        id: uuid(),
        type: "TEXTAREA",
        is_required: 1,
        config: {
          options: [],
        },
      };

      this.store.addItem(item, index + 1);
    },

    // TINYMCE EDITOR
    onEditor(value) {
      value = this.model.help_text;
      const diff = this.findDiff(this.lastValue, value);

      if (diff.value.includes("@")) {
        this.selectVariableDialog = true;
        this.position = diff.position;
      }

      this.lastValue = value;

      // eslint-disable-next-line vue/no-mutating-props
      this.model.help_text = value;
    },

    findDiff(str1, str2) {
      let value = "";
      let position = null;

      str2.split("").forEach(function (val, i) {
        if (val !== str1.charAt(i)) {
          if (position === null) {
            position = i;
          }
          value += val;
        }
      });

      return {
        value,
        position,
      };
    },

    onSelectVariable(selectedVariable) {
      this.selectVariableDialog = false;

      let forwardPosition = null;

      for (let i in this.model.help_text) {
        if (i < this.position) {
          continue;
        }

        if (this.model.help_text[i] === "@") {
          forwardPosition = i;
          break;
        }
      }

      // eslint-disable-next-line vue/no-mutating-props
      this.model.help_text = [
        this.model.help_text.slice(0, forwardPosition),
        `{{${selectedVariable}}}`,
        this.model.help_text.slice(forwardPosition),
      ]
        .join("")
        .replace("}}@", "}}");
    },

    onCardClick() {
      const classClick = event.target.classList;

      if (this.activeCard) {
        if (
          classClick.value === "" ||
          classClick.value.includes("v-switch") ||
          classClick.value.includes("v-tetx-field") ||
          classClick.value.includes("v-tetxarea") ||
          classClick.value.includes("v-select") ||
          classClick.value.includes("v-input") ||
          classClick.value.includes("v-label") ||
          classClick.value.includes("v-icon") ||
          classClick.value.includes("fas") ||
          classClick.value.includes("fas") ||
          classClick.value.includes("icon") ||
          classClick.value.includes("v-btn") ||
          classClick.value.includes("v-text-field__slot") ||
          classClick.value.includes("v-messages") ||
          classClick.value.includes("v-text-field__details") ||
          // lassClick.value.includes("flex") ||
          classClick.value === "editr--content"
        ) {
          return;
        }
      }

      this.activeCard = true;
    },

    getIcon(value) {
      if (value === "CHECKBOX") {
        return "check_box";
      }

      if (value === "RADIO") {
        return "radio_button_checked";
      }

      if (value === "TEXTBLOCK") {
        return "notes";
      }

      if (value === "TEXTAREA") {
        return "short_text";
      }

      if (value === "SIGNATURE") {
        return "create";
      }

      if (value === "DRAW") {
        return "brush";
      }
    },

    removeItem() {
      this.store.removeItem(this.model);
    },

    addFieldItem() {
      // eslint-disable-next-line vue/no-mutating-props
      this.model.config.options.push({
        id: this.model.config.options.length + 1,
      });
    },

    toggleItemVisibilityOnMobile() {
      this.model.not_show_on_mobile = !this.model.not_show_on_mobile;
    },
  },
};
</script>

<style scoped>
.border {
  border-left: 6px solid #cccc !important;
}

.border-color {
  border-left: 6px solid #b9041c !important;
}
</style>

<style>
.tox-notifications-container {
  visibility: hidden !important;
}

.tox-tinymce {
  width: 100% !important;
}

.tinymce-editor {
  min-height: 400px;
}
</style>
