const numeral = require('numeral');

export default class Functions {
    static encode_utf8(s) {
		return unescape(encodeURIComponent(s));
	}

	static delay = ms => new Promise(res => setTimeout(res, ms));

	static decode_utf8(s) {
		return decodeURIComponent(escape(s));
	}

	static async alertConfirm(ask, type, reference) {
		let response = await reference.$swal.fire({
			title: `${ask}`,
			type: type,
			showCancelButton: true,
			confirmButtonColor: "#4caf50",
			cancelButtonColor: "#ff1744",
			confirmButtonText: "Confirmar",
			cancelButtonText: "Cancelar",
			allowOutsideClick: false,
		});
	
		if (response.value) {
			return true;
		}
	
		return false;
	}

	static maskText (text, mask) {
		if (!text) {
			return text;
		}

		let aux;
		let pos = 0;
		let newValue = '';
		let maskLength = text.length;

		for (let i = 0; i <= maskLength; i++) {
			aux = ((mask.charAt(i) === '-') || (mask.charAt(i) === '.') || (mask.charAt(i) === '/'));
			aux = aux || ((mask.charAt(i) === '(') || (mask.charAt(i) === ')') || (mask.charAt(i) === ' '));

			if (aux) {
				newValue += mask.charAt(i);
				maskLength++;
			} else {
				newValue += text.charAt(pos);
				pos++;
			}
		}

		return newValue;
	}

	static validationRequiredField(value) {
		return !!value || "Campo Obrigatório";
	}

	static numeralFilter (value, mask) {
		if (value === undefined) {
			return value;
		}

		return numeral(value).format(mask);
	}

	static formatDate (date) {
		if (!date) return;

		let new_date = '';

		let nArr = date.split('T');
		let nDate = '';

		if (nArr.length > 1) {
			nDate = nArr[0];
		} else {
			nDate = date;
		}

		new_date = `${nDate.split("-")[2]}/${nDate.split("-")[1]}/${
			nDate.split("-")[0]
		}`;

		return new_date;
	}

	static convertMonths (months) {
		let array_months = [
			{ id: "01", name: "Janeiro" },
			{ id: "02", name: "Fevereiro" },
			{ id: "03", name: "Março" },
			{ id: "04", name: "Abril" },
			{ id: "05", name: "Maio" },
			{ id: "06", name: "Junho" },
			{ id: "07", name: "Julho" },
			{ id: "08", name: "Agosto" },
			{ id: "09", name: "Setembro" },
			{ id: "10", name: "Outubro" },
			{ id: "11", name: "Novembro" },
			{ id: "12", name: "Dezembro" }
		];
		if (months) {
			return array_months.filter(month => {
				return months.some(mes => { return mes === month.id; }) ? month.name : null;
			}).map(month => { return month.name; }).join(", ");
		}
	}

	static setSessionStorageSearchTerm(propertyName, value) {
		const newValues = {...JSON.parse(sessionStorage.getItem("searchTerms")), ...{[propertyName]: value}};

		sessionStorage.setItem("searchTerms", JSON.stringify(newValues));
	}

	static getSessionStorageSearchTerm(propertyName) {
		const terms = sessionStorage.getItem("searchTerms");

		if (!terms) {
			return '';
		}

		try {
			const searchedTerm = JSON.parse(sessionStorage.getItem("searchTerms"))[propertyName];

			const isEmptyOrIsOnlySpaces = !searchedTerm || (searchedTerm.replace(/\s/g, '') == '');

			if (isEmptyOrIsOnlySpaces) {
				return '';
			}

			return searchedTerm;
		} catch (error) {
			console.error(error);
			return error;
		}
	}
}
