<template>
    <v-row row wrap>
        <v-col xs="12" md="12" pa-3>
            <span class="required grey--text">
                * Preenchimento obrigatório.
            </span>
        </v-col>

        <v-col xs="12" md="8" pa-3>
            <v-text-field
                label="Nome do formulário"
                v-model="formStore.state.name"
                :rules="[$functions.validationRequiredField]"
                required
                class="required"
            />
        </v-col>
    </v-row>
</template>

<script>
import FormStore from '../../store/FormStore';

export default {
    name: "FormRegisterMainInfo",
	data() {
		return {
			formStore: new FormStore(),
        };
    },
}
</script>

<style>

</style>