import axios from 'axios';

import FloweryService from "./services/FloweryService.js";
import ApiaryGroupService from "./services/ApiaryGroupService.js";
import ApiaryService from "./services/ApiaryService.js";
import GlobalService from "./services/GlobalService.js";
import InventoryMovementService from "./services/InventoryMovementService.js";
import MovementTypeService from "./services/MovementTypeService.js";
import ParameterService from "./services/ParameterService.js";
import PdfService from "./services/PdfService.js";
import LoginService from "./services/LoginService.js";
import MidiaService from "./services/MidiaService.js";
import RoleService from "./services/RoleService.js";
import SupplierService from "./services/SupplierService.js";
import SystemService from "./services/SystemService.js";
import UploadService from "./services/UploadService.js";
import UserGroupService from "./services/UserGroupService.js";
import AggregateService from "./services/AggregateService.js";
import UserService from "./services/UserService.js";
import CircleMapService from "./services/CircleMapService.js";
import ApiaryRemarkService from "./services/ApiaryRemarkService.js";
import ProjectService from "./services/ProjectService.js";
import ProjectDateService from "./services/ProjectDateService.js";
import ProjectAttachmentService from "./services/ProjectAttachmentService.js";
import ProjectApiaryService from "./services/ProjectApiaryService.js";
import ProjectCircleMapService from "./services/ProjectCircleMapService.js";
import TreatRequest from "./services/TreatRequest.js";
import LatLngService from "./services/LatLngService.js";
import SystemVariableService from "./services/SystemVariableService.js";
import HoneyAppointmentService from "./services/HoneyAppointmentService.js";
import BeeswaxAppointmentService from "./services/BeeswaxAppointmentService.js";
import CleaningRecordService from "./services/CleaningRecordService.js";
import FeedRecordService from "./services/FeedRecordService.js";
import DiseaseTreatmentRecordService from "./services/DiseaseTreatmentRecordService.js";
import BeeSwarmCaptureService from "./services/BeeSwarmCaptureService.js";
import DocumentService from "./services/DocumentService.js";
import ProjectFormResponseService from "./services/ProjectFormResponseService.js";
import FormResponseService from "./services/FormResponseService.js";
import ProjectNotificationService from "./services/ProjectNotificationService.js";

class MinamelServices {
	constructor (urls, token, latLngType) {
		this.token = token;
		this.urls = urls;
		this.latLngType = latLngType;
	}

	createStandard (apiVersion) {
		const standard = axios.create({
			baseURL: this.urls[apiVersion],
			headers: {
				'token': this.token()
			},
			timeout: 3600000,
			transformResponse: [
				function (data) {
					return data;
				}
			]
		});

		standard.interceptors.response.use((response) => response, (error) => {
			const pathname = window.location.pathname;

			if (error && error.response && error.response.status === 401 && pathname !== '/login') {
				window.location.href = '/login';
			}

			throw error;
		});

		return standard;
	}
	FloweryService () {
		return FloweryService.build().setHttp(this.createStandard('v1'));
	}
	FloweryServiceV2 () {
		return FloweryService.build().setHttp(this.createStandard('v2'));
	}
	ApiaryGroupService () {
		return ApiaryGroupService.build().setHttp(this.createStandard('v1'));
	}
	ApiaryGroupServiceV2 () {
		return ApiaryGroupService.build().setHttp(this.createStandard('v2'));
	}
	ApiaryService () {
		return ApiaryService.build().setHttp(this.createStandard('v1'));
	}
	ApiaryServiceV2 () {
		return ApiaryService.build().setHttp(this.createStandard('v2'));
	}
	GlobalService () {
		return GlobalService.build().setHttp(this.createStandard('v1'));
	}
	InventoryMovementService () {
		return InventoryMovementService.build().setHttp(this.createStandard('v1'));
	}
	MovementTypeService () {
		return MovementTypeService.build().setHttp(this.createStandard('v1'));
	}
	ParameterService () {
		return ParameterService.build().setHttp(this.createStandard('v1'));
	}
	PdfService () {
		return PdfService.build().setHttp(this.createStandard('v1'));
	}
	LoginService () {
		return LoginService.build().setHttp(this.createStandard('v1'));
	}
	RoleService () {
		return RoleService.build().setHttp(this.createStandard('v1'));
	}
	SupplierService () {
		return SupplierService.build().setHttp(this.createStandard('v1'));
	}
	SupplierServiceV2 () {
		return SupplierService.build().setHttp(this.createStandard('v2'));
	}
	SystemService () {
		return SystemService.build().setHttp(this.createStandard('v1'));
	}
	SystemServiceV2 () {
		return SystemService.build().setHttp(this.createStandard('v2'));
	}
	UploadService () {
		return UploadService.build().setHttp(this.createStandard('v1'));
	}
	UserGroupService () {
		return UserGroupService.build().setHttp(this.createStandard('v1'));
	}
	UserGroupServiceV2 () {
		return UserGroupService.build().setHttp(this.createStandard('v2'));
	}
	AggregateService () {
		return AggregateService.build().setHttp(this.createStandard('v1'));
	}
	UserService () {
		return UserService.build().setHttp(this.createStandard('v1'));
	}
	UserServiceV2 () {
		return UserService.build().setHttp(this.createStandard('v2'));
	}
	CircleMapService () {
		return CircleMapService.build().setHttp(this.createStandard('v1'));
	}
	CircleMapServiceV2 () {
		return CircleMapService.build().setHttp(this.createStandard('v2'));
	}
	ApiaryRemarkService () {
		return ApiaryRemarkService.build().setHttp(this.createStandard('v1'));
	}
	ProjectService () {
		return ProjectService.build().setHttp(this.createStandard('v1'));
	}
	ProjectServiceV2 () {
		return ProjectService.build().setHttp(this.createStandard('v2'));
	}
	ProjectDateService () {
		return ProjectDateService.build().setHttp(this.createStandard('v1'));
	}
	ProjectApiaryService () {
		return ProjectApiaryService.build().setHttp(this.createStandard('v1'));
	}
	ProjectAttachmentService () {
		return ProjectAttachmentService.build().setHttp(this.createStandard('v2'));
	}
	ProjectCircleMapService () {
		return ProjectCircleMapService.build().setHttp(this.createStandard('v2'));
	}
	SystemVariableService () {
		return SystemVariableService.build().setHttp(this.createStandard('v2'));
	}
	HoneyAppointmentService () {
		return HoneyAppointmentService.build().setHttp(this.createStandard('v2'));
	}
	BeeswaxAppointmentService () {
		return BeeswaxAppointmentService.build().setHttp(this.createStandard('v2'));
	}
	CleaningRecordService () {
		return CleaningRecordService.build().setHttp(this.createStandard('v2'));
	}
	FeedRecordService () {
		return FeedRecordService.build().setHttp(this.createStandard('v2'));
	}
	DiseaseTreatmentRecordService () {
		return DiseaseTreatmentRecordService.build().setHttp(this.createStandard('v2'));
	}
	BeeSwarmCaptureService () {
		return BeeSwarmCaptureService.build().setHttp(this.createStandard('v2'));
	}
	DocumentService () {
		return DocumentService.build().setHttp(this.createStandard('v2'));
	}
	FormResponseService () {
		return FormResponseService.build().setHttp(this.createStandard('v2'));
	}
	ProjectFormResponseService () {
		return ProjectFormResponseService.build().setHttp(this.createStandard('v2'));
	}
	ProjectNotificationService () {
		return ProjectNotificationService.build().setHttp(this.createStandard('v2'));
	}
	TreatRequest () {
		return TreatRequest.build();
	}
	LatLngService () {
		return LatLngService.build(this.latLngType());
	}
	MidiaService() {
		return MidiaService.build().setHttp(this.createStandard('v2'));
	}
	getUrl () {
		return this.urls['v1'];
	}
	getToken () {
		return this.token();
	}
}

export default MinamelServices;
