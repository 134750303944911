<template>
  <v-card class="elevation-0" :class="'ma-4'">
    <v-row row>
      <v-col xs="12" sm="12" md="5" class="text-xs-left">
        <v-text-field
          v-model="filter.term"
          ref="search"
          label="Faça a sua busca"
          clear-icon="close"
          clearable
          class="pl-1 pt-3"
          append-icon="search"
          @click:append="getData"
          @keyup="getData"
          @keyup.enter="getData"
        />
      </v-col>

      <v-col xs="3" md="2" class="text-xs-left">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn large color="normal" v-on="on" @click="toggleFilters">
              <v-icon>filter_list</v-icon>
            </v-btn>
          </template>
          <span>Clique para selecionar os filtros</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-expand-transition>
      <v-card v-show="expandFilters" class="elevation-1 pa-2 mb-4">
        <v-row row>
          <v-col xs="12">
            <v-label>Selecione os campos para fazer a busca</v-label>
          </v-col>
        </v-row>
        <v-row row wrap>
          <v-col xs="12" md="4" sm="6" pa-3 pt-4>
            <v-menu
              v-model="datePickerMenu"
              :close-on-content-click="false"
              full-width
              max-width="290"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="$functions.formatDate(filter.date)"
                  clearable
                  @click:clear="filter.date = null"
                  label="Data de preenchimento"
                  readonly
                  v-on="on"
                />
              </template>
              <v-date-picker
                locale="pt-br"
                v-model="filter.date"
                @change="datePickerMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col xs="12" md="4" sm="6" pa-3>
            <v-autocomplete
              v-model="filter.status"
              clearable
              chips
              deletable-chips
              color="primary"
              label="Status"
              :items="status"
              @change="getData"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-card>
    </v-expand-transition>

    <v-data-table
      :headers="table.headers"
      :items="table.items"
      :loading="table.loading"
      class="elevation-1"
      hide-default-footer
    >
      <template v-slot:[`item.supplier_id`]="{ item }">
        {{ item.supplier ? item.supplier.name : " - " }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip dark :color="formStatusColor(item)">
          {{ formStatus(item) }}
        </v-chip>
      </template>

      <template v-slot:[`item.created_at`]="{ item }">
        {{ $functions.formatDate(item.created_at) }}
      </template>

      <template v-slot:[`item.version_number`]="{ item }">
        <v-chip>
          <span class="primary--text font-weight-bold">
            {{ item.form ? item.form.version_number : "" }}
          </span>
        </v-chip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn :to="`responses/edit/${item.import_key}`" icon v-on="on">
              <v-icon color="#707070">edit</v-icon>
            </v-btn>
          </template>
          <span>Editar respostas</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn :to="`responses/view/${item.import_key}`" icon v-on="on">
              <v-icon color="#707070">grading</v-icon>
            </v-btn>
          </template>
          <span>Ver respostas</span>
        </v-tooltip>

        <default-remove-button
          @click="() => remove(item.import_key)"
          :loading="removing == item.import_key"
        />
      </template>
    </v-data-table>
    <div class="text-xs-center pa-4">
      <v-pagination
        v-model="table.pagination.page"
        :length="table.pagination.pages"
        v-bind:disabled="table.disabled"
        :total-visible="table.pagination.totalVisible"
        prev-icon="arrow_left"
        next-icon="arrow_right"
        primary
      />
    </div>
  </v-card>
</template>
<script>
import DefaultRemoveButton from "@/shared/components/buttons/DefaultRemoveButton.vue";
import EFormResponseStatus from "@/modules/forms/domain/enums/FormResponseStatusEnum";
export default {
  components: { DefaultRemoveButton },
  name: "FormResponses",

  data() {
    return {
      forms: [],
      projectId: null,
      expandFilters: false,
      datePickerMenu: false,
      filter: {
        term: "",
      },
      status: [
        { value: true, text: "Preenchido" },
        { value: false, text: "Pendente" },
      ],
      removing: 0,
      table: {
        headers: [
          {
            text: "Status",
            align: "left",
            value: "status",
          },
          {
            text: "Produtor/agregado",
            align: "left",
            value: "supplier_id",
          },
          {
            text: "Formulário",
            align: "left",
            value: "form.name",
          },
          {
            text: "Data de preenchimento",
            align: "left",
            value: "created_at",
          },
          {
            text: "Revisão",
            align: "left",
            value: "version_number",
          },
          {
            text: "",
            align: "right",
            value: "actions",
          },
        ],
        items: [],
        loading: false,
        pagination: {
          perPage: this.CONSTANTS.integer.table.numberOfRowPerPage,
          page: 1,
          total: 0,
          pages: 1,
          totalVisible: this.CONSTANTS.integer.table.totalPagesVisible,
        },
      },
      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: null,
        y: "bottom",
        multiLine: false,
      },
    };
  },

  async mounted() {
    this.projectId = this.$route.params.id;

    await this.getData();
  },

  methods: {
    formStatus(item) {
      return EFormResponseStatus.serializedStatus(item.status);
    },
    formStatusColor(item) {
      switch (item.status) {
        case EFormResponseStatus.ANSWERED:
          return "warning";
        case EFormResponseStatus.IN_PROGRESS:
          return "yellow darken-2";
        case EFormResponseStatus.APPROVED:
          return "success";
        case EFormResponseStatus.DISAPPROVED:
          return "error;";
        default:
          return "grey";
      }
    },

    async remove(item_id) {
      try {
        const question = "Deseja realmente excluir este item?";
        const type = "question";

        const confirm = await this.$functions.alertConfirm(
          question,
          type,
          this
        );

        if (!confirm) {
          return;
        }

        this.removing = item_id;

        const res = await this.minamelServices
          .ProjectFormResponseService()
          .delete(item_id);

        if (res) {
          this.table.pagination.page = 1;
          await this.getData();
        }
      } catch (error) {
        console.error("FormResponses::remove -", error);
      } finally {
        this.removing = null;
      }
    },

    async getData() {
      try {
        this.table.loading = true;

        const params = {
          page: this.table.pagination.page,
          perPage: this.table.pagination.perPage,
          term: this.filter.term ? this.filter.term : "",
          status: this.filter.status ? this.filter.status : "",
          date: this.filter.date ? this.filter.date : "",
        };

        const response = await this.minamelServices
          .ProjectFormResponseService()
          .getByProject(this.projectId, params);

        this.table.perPage = response.meta.per_page;
        this.table.items = response.data;
        this.table.pagination.pages = response.meta.last_page;
        this.table.loading = false;
      } catch (err) {
        console.error(err);
        this.snackbar.color = "error";
        this.snackbar.message = "Ocorreu um erro as buscar os dados.";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },

    toggleFilters() {
      this.expandFilters = !this.expandFilters;
    },
  },

  watch: {
    "table.pagination.page": function () {
      this.getData();
    },

    "filter.date": function () {
      this.getData();
    },
  },
};
</script>
