import Aggregates from './Aggregates';
import Calendar from './Calendar';
import Suppliers from './Suppliers';
import InventoryMovements from './InventoryMovements';
import MovementTypes from './MovementTypes';
import Operations from './Operations';
import Users from './Users';
import UserGroups from './UserGroups';
import MyUser from './MyUser';
import Flowery from './Flowery';
import ApiaryGroups from './ApiaryGroups';
import Apiary from './Apiary';
import Roles from './Roles';
import Systems from './Systems';
import CircleMaps from './CircleMaps';
import EstadosCidades from './estadosCidades';
import InputLatLng from './inputLatLng';
import MyMap from './Maps';
import Project from './Project';
import SupplierBalance from './SupplierBalance';
import SystemVariables from './SystemVariables';
import FieldNotes from './FieldNotes';
import Documents from './Documents';

export default {...Aggregates, ...Suppliers, ...Calendar, ...InventoryMovements, ...MovementTypes, ...Operations, ...Users, ...UserGroups, ...MyUser, ...Flowery, ...ApiaryGroups, ...Apiary, ...EstadosCidades, ...Roles, ...Systems, ...CircleMaps, ...MyMap, ...Project, ...InputLatLng, ...SupplierBalance, ...SystemVariables, ...FieldNotes, ...Documents};
// teste
