import Vue from "vue";

/**
 * Singleton Variable
 * ! DONT REMOVE !
 */
let instance;

class FormStore {
    /**
     * Singleton constructor
     * @returns FormStore
     */
    constructor() {
        this.state = this.createState();

        if (!instance) {
            instance = this;
        }

        return instance;
    }

    createState() {
        return Vue.observable({
            error: '',
            loading: false,
            filled_by: '',
            form_id: null,
            items: [],
            page: 1,
            perPage: 8,
            name: "",
        });
    }

    clearData() {
        this.state.filled_by = '';            
        this.state.form_id = null;
        this.state.items = [];
        this.state.page = 1;
        this.state.perPage = 8;
        this.state.name = "";
    }

    removeItem(item) {
        this.state.items = this.state.items.filter((formField) => {
            if (formField.id == item.id) {
                return false;
            }
            
            return true;
        });
    }

    reorderItem(item, fromIndex, toIndex) {
        this.state.items.splice(fromIndex, 1);

        setTimeout(() => {
            this.state.items.splice(toIndex, 0, item);
        }, 300);
    }

    addItem(item, index = 0) {
        this.state.items.splice(index, 0, item);
    }

    setLoading(value = false) {
        this.state.loading = value;
    }

    sortAllItems() {
        this.state.items.forEach((item, index) => {
            const toSerializedItem = this.state.items[index];
            toSerializedItem.index = index; 
            toSerializedItem.filled_by = this.state.filled_by; 

            if (toSerializedItem.help_text && toSerializedItem.help_text["preview"]) {
                toSerializedItem.help_text = toSerializedItem.help_text.preview;
              }
        });
    }
}

export default FormStore;