<template>
  <v-form ref="form">
    <default-form-layout>
      <template v-slot:title>
        <h2>{{headerText}} Produtor</h2>
      </template>
      <template v-slot:body>
        <v-progress-linear v-show="loading" indeterminate />

        <!-- Tabs -->
        <v-tabs v-model="activeTab">
          <v-tab>Dados</v-tab>
          <v-tab>Agregados</v-tab>
          <v-tab>Documentos</v-tab>
          <v-tab v-if="id != null">Apiários</v-tab>
        </v-tabs>

        <v-tabs-items v-model="activeTab">

          <!-- Aba Dados -->
          <v-tab-item style="padding: 10px 0;">

            <v-col>
              <!-- Linha 1 -->
              <v-row style="gap: 10px;">
                <v-select
                  label="Tipo"
                  class="required"
                  color="primary"
                  item-value="id"
                  item-text="name"
                  v-model="register.type"
                  :items="types"
                  :rules="[formRules.required]"
                />

                <v-text-field
                  label="Código"
                  v-model="register.code"
                />

                <v-text-field 
                  label="Código OESA" 
                  v-model="register.oesa" 
                />

                <v-text-field
                  label="Nome"
                  class="required"
                  v-model="register.name"
                  :rules="[formRules.required]"
                />

                <v-text-field 
                  label="Apelido"
                  v-model="register.nick"
                />
              </v-row>

              <!-- Linha 2 -->
              <v-row style="gap: 10px;">
                <v-text-field
                  label="Data de Nascimento"
                  type="date"
                  v-model="register.birth"
                />

                <v-text-field
                  label="Atualizado em"
                  type="date"
                  v-model="register.updated_at"
                  readonly
                />

                <v-text-field
                  label="CPF"
                  class="required"
                  v-model="register.cnpj"
                  :rules="[formRules.required]"
                />

                <v-text-field
                  label="E-mail"
                  type="email"
                  v-model="register.email"
                />

                <v-text-field
                  label="Telefone"
                  v-model="register.phone"
                />
              </v-row>

              <!-- Linha 3 -->
              <v-row style="gap: 10px;">
                <v-text-field
                  label="Insc. de Produtor Rural"
                  v-model="register.inscription"
                />

                <v-text-field
                  label="Endereço"
                  v-model="register.address_description"
                />

                <v-text-field
                  label="Número"
                  v-model="register.address_number"
                />

                <v-text-field
                  label="CEP"
                  v-model="register.cep"
                />
              </v-row>

              <!-- Linha 4 -->
              <v-row style="gap: 10px;">
                <EstadosComponent
                  :class="'required'"
                  :value="register.uf"
                  @updateEstado="updateListCidades"
                />

                <CidadesComponent
                  :class="'required'"
                  :city="register.city_name"
                  :value="register.uf"
                  @updateCidade="updateSelectedCidade"
                />

                <v-select
                  chips
                  clearable
                  deletable-chips
                  label="Grupo"
                  color="primary"
                  item-value="id"
                  item-text="name"
                  v-model="register.apiary_group_id"
                  :items="apiaryGroups"
                />

                <v-switch
                  color="#b9041c"
                  v-model="register.active"
                  :label="register.active ? 'Ativo' : 'Inativo'"
                />
              </v-row>
            </v-col>
          </v-tab-item>

          <v-tab-item></v-tab-item>
          <v-tab-item></v-tab-item>

          <!-- Aba Apiários -->
          <v-tab-item style="padding: 10px 0;">
            <template>
              <v-row>
                <v-col xs="7" md="4">
                  <v-text-field
                    v-model="filter.term"
                    ref="search"
                    label="Faça a sua busca"
                    clear-icon="close"
                    clearable
                    append-icon="search"
                    @click:append="getApiaryList"
                    @keyup="getApiaryList"
                    @keyup.enter="getApiaryList"
                  />
                </v-col>
                <v-col xs="3" md="4" class="text-xs-left">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        large
                        color="normal"
                        v-on="on"
                        @click="toggleFilters"
                        v-shortkey="['ctrl', 'f']"
                      >
                        <v-icon>filter_list</v-icon>
                      </v-btn>
                    </template>
                    <span>Clique para selecionar os filtros</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-expand-transition>
                <v-row row wrap v-show="expandFilters">
                  <v-col md="12">
                    <v-card class="elevation-1 pa-3 mb-4">
                      <v-row row>
                        <v-col xs="12">
                          <v-label> Selecione os campos para fazer a busca </v-label>
                        </v-col>
                      </v-row>

                      <v-row row wrap>
                        <v-col xs="12" md="3" pa-3>
                          <v-select
                            chips
                            deletable-chips
                            clearable
                            v-model="filter.active"
                            :items="activeItems"
                            label="Ativo"
                            @change="getApiaryList"
                          />
                        </v-col>

                        <v-col xs="12" md="3" pa-3>
                          <v-select
                            chips
                            deletable-chips
                            clearable
                            v-model="filter.flowery"
                            :items="floweries"
                            item-text="name"
                            item-value="id"
                            label="Florada"
                            @change="getApiaryList"
                          />
                        </v-col>

                        <v-col xs="12" md="3" pa-3>
                          <v-select
                            chips
                            deletable-chips
                            clearable
                            v-model="filter.permanence"
                            :items="permanenceItems"
                            label="Permanência"
                            @change="getApiaryList"
                          />
                        </v-col>

                        <v-col xs="12" md="3" pa-3>
                          <v-select
                            chips
                            deletable-chips
                            clearable
                            v-model="filter.quality"
                            :items="qualityItems"
                            label="Qualidade"
                            @change="getApiaryList"
                          />
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-expand-transition>
              <v-data-table
                :headers="table.headers"
                :items="table.items"
                :loading="table.loading"
                class="elevation-1"
                hide-default-footer
              >
                <template v-slot:[`item.active`]="{ item }">
                  <v-chip v-if="item.active == 1" color="green" text-color="white"
                    >Ativo</v-chip
                  >
                  <v-chip v-else color="#5e636b" text-color="white">Inativo</v-chip>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        small
                        @click="openDialogDetails(item)"
                        class="mx-1"
                        icon
                        text
                        v-on="on"
                      >
                        <v-icon color="#707070">visibility</v-icon>
                      </v-btn>
                    </template>
                    <span>Ver detalhes</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn small @click="maps(item)" class="mx-1" icon text v-on="on">
                        <v-icon color="#707070">gps_fixed</v-icon>
                      </v-btn>
                    </template>
                    <span>Visualizar no mapa</span>
                  </v-tooltip>
                </template>
              </v-data-table>
              <div>
                <v-card class="pa-3">
                  <v-row wrap>
                    <v-col xs="12" md="12" class="text-xs-center">
                      <v-pagination
                        v-model="table.pagination.page"
                        :length="table.pagination.pages"
                        v-bind:disabled="table.disabled"
                        :total-visible="table.pagination.totalVisible"
                        prev-icon="arrow_left"
                        next-icon="arrow_right"
                        primary
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </template>
          </v-tab-item>
        </v-tabs-items>
      </template>

      <template v-slot:actions>
        <v-btn style="color: white" color="green" @click="save">Salvar</v-btn>
        <v-btn color="error" text @click="cancel">Cancelar</v-btn>
      </template>
    </default-form-layout>

    <!-- maps dialog -->
    <v-dialog v-model="dialogMaps" fullscreen hide-overlay persistent>
        <v-card>
          <v-toolbar
            style="position: absolute; top: 0; z-index: 9999; width: 100%"
            dark
            color="primary"
          >
            <v-btn icon dark @click="this.closeDialogMaps">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>
              {{ apiaryMaps ? apiaryMaps.name : "" }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <MyMap ref="myMap"></MyMap>
        </v-card>
      </v-dialog>
      <!-- fim maps dialog -->

    <!-- detail dialog-->
    <v-dialog v-model="dialogDetails" width="1000">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="this.closeDialogDetails">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Apiário {{ selectedItem.name }} -
            {{ selectedItem.active == 1 ? "Ativo" : "Inativo" }}
          </v-toolbar-title>

          <v-spacer></v-spacer>
          <v-toolbar-items class="hidden-sm-and-down">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="maps(selectedItem), (dialogDetails = !dialogDetails)"
                  icon
                  text
                  dark
                  v-on="on"
                >
                  <v-icon>gps_fixed</v-icon>
                </v-btn>
              </template>
              <span>Visualizar no mapa</span>
            </v-tooltip>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text style="padding-top: 10px;">
          <v-row grid-list-md row wrap>
            <v-col md="4" pt-2>
              <v-label>Grupo de apiários</v-label>
              <span class="d-block font-weight-bold">
                {{
                  selectedItem.apiaryGroup ? selectedItem.apiaryGroup.name : "-"
                }}
              </span>
            </v-col>

            <v-col md="4" pt-2>
              <v-label>Florada</v-label>
              <span class="d-block font-weight-bold">
                {{
                  selectedItem.floweries
                    ? getFloweryNames(selectedItem.floweries)
                    : "-"
                }}
              </span>
            </v-col>

            <v-col md="4" pt-4>
              <v-label>Localidade</v-label>
              <span class="d-block font-weight-bold">
                {{ selectedItem.locale }}
              </span>
            </v-col>

            <v-col md="4" pt-4>
              <v-label>Cidade/UF</v-label>
              <span class="d-block font-weight-bold">
                {{ selectedItem.city }}/{{ selectedItem.uf }}
              </span>
            </v-col>

            <v-col md="4" pt-4>
              <v-label>Quantidade de colmeias</v-label>
              <span class="d-block font-weight-bold">
                {{ selectedItem.hive_count }}
              </span>
            </v-col>

            <v-col md="4" pt-4>
              <v-label>Qualidade</v-label>
              <span class="d-block font-weight-bold">
                {{ getQualityName(selectedItem.quality) }}
              </span>
            </v-col>

            <v-col md="4" pt-4>
              <v-label>Permanência</v-label>
              <span class="d-block font-weight-bold">
                {{ selectedItem.permanence }}
              </span>
            </v-col>

            <v-col md4 pt-4 v-if="selectedItem.permanence === 'Migratório'">
              <v-label>Meses</v-label>
              <span class="d-block font-weight-bold">
                {{
                  selectedItem.months ? getMonthNames(selectedItem.months) : "-"
                }}
              </span>
            </v-col>

            <v-col md="4" pt-4 v-if="selectedItem.latitude || selectedItem.longitude">
              <v-label>Latitude/Longitude</v-label>
              <div class="coord">
                <v-tooltip transition="fab-transition" top>
                  <v-chip
                    slot="activator"
                    small
                    label
                    color="#0ebcff"
                    text-color="white"
                  >
                    {{ getLatLngValues(selectedItem.latitude, "lat") }}
                  </v-chip>
                  <span>
                    <ul v-for="lat in latitudeOptions" :key="lat.type">
                      <li>
                        {{ `${lat.type}: ${convertCoordinates(lat.newValue)}` }}
                      </li>
                    </ul>
                  </span>
                </v-tooltip>
                <v-tooltip transition="fab-transition" top>
                  <v-chip
                    slot="activator"
                    small
                    label
                    color="#d4a041"
                    text-color="white"
                  >
                    {{ getLatLngValues(selectedItem.longitude, "lng") }}
                  </v-chip>
                  <span>
                    <ul v-for="lng in longitudeOptions" :key="lng.type">
                      <li>
                        {{ `${lng.type}: ${convertCoordinates(lng.newValue)}` }}
                      </li>
                    </ul>
                  </span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogDetails = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import DefaultFormLayout from "@/shared/components/layout/DefaultFormLayout.vue";
import EstadosComponent from "../estadosCidades/EstadosComponent";
import CidadesComponent from "../estadosCidades/CidadesComponent";
import MyMap from "../Maps/MyMap";

export default {
  components: { 
    DefaultFormLayout,
    EstadosComponent,
    CidadesComponent,
    MyMap,
  },
  name: "RegisterSupplier",
  data() {
    return {
      id: null,
      dialogMaps: false,
      dialogDetails: false,
      activeTab: 0,
      apiaryMaps: null,
      selectedItem: {},
      headerText: "Novo",
      expandFilters: false,
      formRules: { required: (v) => !!v || "Campo obrigatório!" },
      register: { type: null, active: true },
      loading: false,
      types: ["PRODUTOR PRINCIPAL", "APICULTOR"],
      apiaryGroups: [],
      filter: {},
      floweries: [],
      activeItems: [
        { value: "1", text: "Sim" },
        { value: "0", text: "Não" },
      ],
      permanenceItems: [
        { value: "Fixo", text: "Fixo" },
        { value: "Migratório", text: "Migratório" },
      ],
      qualityItems: [
        { value: 1, text: "Convencional" },
        { value: 2, text: "Orgânico" },
      ],
      table: {
        headers: [
        {
            text: "Status",
            align: "left",
            value: "active",
            sortable: false,
          },
          {
            text: "Descrição",
            align: "left",
            value: "name",
          },
          {
            text: "Cidade/UF",
            align: "left",
            value: "city",
          },
          {
            text: "Localidade",
            width: "200px",
            align: "left",
            value: "locale",
          },
          {
            text: "Permanência",
            align: "center",
            value: "permanence",
          },
          {
            text: "Colmeias",
            align: "center",
            value: "hive_count",
            sortable: false,
          },
          {
            text: "",
            width: "190px",
            align: "right",
            value: "actions",
            sortable: false,
          },
        ],
        items: [],
        loading: false,
        pagination: {
          page: 1,
          perPage: this.CONSTANTS.integer.table.numberOfRowPerPage,
          totalVisible: this.CONSTANTS.integer.table.totalPagesVisible,
        },
      },
      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: null,
        y: "bottom",
        multiLine: false,
      },
    };
  },
  async mounted() {
    try {
      this.loading = true;

      this.id = this.$route.params.id;

      if (this.id) {
        this.headerText = "Alterar";
        await Promise.all([this.getData(), this.getApiaryList(), this.getFloweries()]);
      }

      await this.getApiaryGroups();

    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async getData() {
      const response = await this.minamelServices
        .SupplierServiceV2()
        .show(this.id);

      this.register = response.data;
    },

    async getApiaryList() {
      try {
        this.table.loading = true;

        const params = {
          supplierId: this.id,
          page: this.table.pagination.page,
          perPage: this.table.pagination.perPage,
          term: this.filter.term ?? "",
          active: this.filter.active ?? "",
          flowery: this.filter.flowery ?? "",
          quality: this.filter.quality ?? "",
          permanence: this.filter.permanence ?? "",
        };

        const response = await this.minamelServices
          .ApiaryServiceV2()
          .getListByProducer(params);

        this.table.items = response.data;
        this.table.pagination.pages = response.meta.last_page;
        this.table.loading = false;
      } catch (err) {
        this.table.loading = false;
      }
    },

    async getApiaryGroups() {
      const response = await this.minamelServices
        .ApiaryGroupServiceV2()
        .getAll({ active: 1 });

      this.apiaryGroups = response.data;
    },

    async getFloweries() {
      const response = await this.minamelServices
        .FloweryServiceV2()
        .get({ active: 1 });

      this.floweries = response.data;
    },

    async save() {
      const formValidate = this.$refs.form.validate();

      if (!formValidate) {
        this.snackbar.color = "warning";
        this.snackbar.message = "Verifique os campos em destaque.";
        this.$root.$SnackBar.show(this.snackbar);
        return;
      }

      if (this.id) {
        this.update();
        return;
      }

      this.create();
    },

    async create() {
      try {
        const response = await this.minamelServices
          .SupplierServiceV2()
          .store(this.register);

        if (response.message) {
          this.$swal.fire({
            title: "Atenção",
            text: response.message,
            type: "info",
            showCancelButton: false,
            allowOutsieClick: false,
            width: 500,
          });
          return;
        }

        if (response) {
          this.snackbar.color = "success";
          this.snackbar.message = "Produtor incluído com sucesso!";
          this.$root.$SnackBar.show(this.snackbar);

          this.$router.back();
        }
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message = "Ocorreu um erro ao realizar o cadastro.";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },

    async update() {
      try {
        const response = await this.minamelServices
          .SupplierServiceV2()
          .update(this.id, this.register);

        if (response.message) {
          this.$swal.fire({
            title: "Atenção",
            text: response.message,
            type: "info",
            showCancelButton: false,
            allowOutsieClick: false,
            width: 500,
          });
          return;
        }

        if (response) {
          this.snackbar.color = "success";
          this.snackbar.message = "Produtor alterado com sucesso!";
          this.$root.$SnackBar.show(this.snackbar);

          this.$router.back();
        }
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message = "Ocorreu um erro ao realizar a alteração.";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },

    updateSelectedCidade(value) {
      this.register.cityName = value;
    },

    updateListCidades(value) {
      this.$set(this.register, 'uf', value);

      if (!value) this.updateSelectedCidade(null);
    },

    toggleFilters() {
      this.expandFilters = !this.expandFilters;
    },

    openDialogDetails(item) {
      this.selectedItem = item;

      const apGroup = this.apiaryGroups.filter((ap) => ap.id === item.apiary_group_id);

      if (apGroup) this.selectedItem.apiaryGroup = apGroup[0];
      
      this.dialogDetails = true;
    },

    closeDialogDetails() {
      this.dialogDetails = false;
    },

    convertCoordinates(value) {
      if (!value) {
        return "";
      }

      const arr = value.split(".");

      if (!arr || !arr[0] || !arr[1]) {
        return "";
      }

      return `${arr[0]}.${arr[1].substring(0, 2)}°`;
    },

    getMonthNames(months) {
      months = months.split(",");
      let arr = [
        { id: "01", name: "Janeiro" },
        { id: "02", name: "Fevereiro" },
        { id: "03", name: "Março" },
        { id: "04", name: "Abril" },
        { id: "05", name: "Maio" },
        { id: "06", name: "Junho" },
        { id: "07", name: "Julho" },
        { id: "08", name: "Agosto" },
        { id: "09", name: "Setembro" },
        { id: "10", name: "Outubro" },
        { id: "11", name: "Novembro" },
        { id: "12", name: "Dezembro" },
      ];
      if (months) {
        return arr
          .filter((month) => {
            return months.some((mes) => {
              return mes === month.id;
            })
              ? month.name
              : null;
          })
          .map((month) => {
            return month.name;
          })
          .join(", ");
      }
    },

    getFloweryNames(floweries) {
      let data = "";
      let cont = 1;

      floweries.forEach((item) => {
        data += item.name;

        if (floweries.length !== cont) {
          data += ", ";
          cont += 1;
        }
      });

      return data;
    },

    getQualityName(value) {
      if (value === 1) return "Convencional";

      if (value === 2) return "Orgânico";

      return "-";
    },

    getLatLngValues(value) {
      return this.convertCoordinates(value);
    },

    async maps(item) {
      item.supplier_name = this.register.name;
      this.apiaryMaps = item;

      const observations = item.observations;
      const blockMap = true;
      this.dialogMaps = true;

      await this.$functions.delay(500);

      await this.$refs.myMap.loadDataMap(
        this.apiaryMaps,
        observations,
        blockMap
      );

      setTimeout(() => {
        this.openDialogMaps();
      }, 400);
    },

    async openDialogMaps() {
      this.dialogMaps = true;

      await this.$functions.delay(500);

      document.querySelector("html").style.overflow = "hidden";
    },

    async closeDialogMaps() {
      this.dialogMaps = false;

      let marker = this.$refs.myMap.getMarkers().marker;
      let remarks = this.$refs.myMap.getMarkers().observations;

      if (marker) {
        marker.id = this.apiaryMaps.id;
        await this.$refs.myMap.updateApiaryRemark(this.apiaryMaps.id, remarks);
      }

      this.dialogMaps = false;

      document.querySelector("html").style.overflow = null;
      this.getData();
    },

    cancel() {
      this.$router.back();
    }
  },

  watch: {
    "table.pagination.page": function () {
      this.getApiaryList();
    },

    "register": function () {
      if (this.register.birth) {
        this.register.birth = this.register.birth.split('T')[0];
      }

      if (this.register.updated_at) {
        this.register.updated_at = this.register.updated_at.split('T')[0];
      }
    }
  },
};
</script>

<style>
.coord {
  display: flex;
  gap: 10px;
}
</style>